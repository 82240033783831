<template>
  <div>
    <b-modal
      :id="id"
      cancel-variant="outline-primary"
      ok-title="Lưu"
      cancel-title="Hủy bỏ"
      centered
      size="xl"
      no-close-on-backdrop
      :title="title"
      @ok="accept($event)"
      @hidden="resetData"
    >
      <my-tabs
        :listTabs="listTabs"
        defaultActive="user-edit-tab-account"
        typeCss="active-full"
        @setActive="setActive"
      >
      </my-tabs>
      <validation-observer ref="rule">
        <InformationAll
          v-if="isRendered('general')"
          id="general"
          refs="information"
          :type="type"
          :dataGeneral="dataGeneral"
          class="tab-pane"
          :class="{active:activeTab==='general'}"
        />
        <TableLaborRecruitment
          v-if="isRendered('worker')"
          id="worker"
          class="tab-pane"
          :collectBussinessId="collectBussinessId"
          :class="{active:activeTab==='worker'}"
        />
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>

import {
  BModal,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import MyTabs from '@/components/tabs/MyTabs.vue'

export default {
  components: {
    BModal,
    ValidationObserver,
    MyTabs,
    InformationAll: () => import('./InformationAll.vue'),
    TableLaborRecruitment: () => import('./TableLaborRecruitment.vue'),
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    dataDetail: {
      type: Object,
      default: _ => {},
    },
    type: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      collectBussinessId: null,
      activeTab: null,
      listTabs: [
        {
          key: 'general',
          title: 'Thông tin chung',
          icon: 'InfoIcon',
          isRendered: false,
        },
        {
          key: 'worker',
          title: 'Nhu cầu tuyển dụng theo nghề, trình độ trong 6 tháng tới',
          icon: 'BriefcaseIcon',
          isDisabled: true,
          isRendered: false,
        },
      ],
      dataGeneral: {
        name: '',
        identityCardNumber: '',
        collectBussinessType: '',
        provinceId: 0,
        province: '',
        districtId: 0,
        district: '',
        wardId: 0,
        ward: '',
        address: '',
        isIndustrial: true,
        industrialArea: '',
        phoneNumber: '',
        email: '',
        manufacturingType: '',
        mainProduct: '',
        scaleWorker: '',
        numberWorker: 0,
        dateCollect: null,
      },
    }
  },
  watch: {
    $route(to, from) {
      if (to && to.params.type) {
        this.activeTab = to.params.type
        const tab = this.listTabs.find(x => x.key === this.activeTab)
        if (tab) tab.isRendered = true
      }
    },

    async dataDetail(val) {
      if (val && this.type === 'edit') {
        this.dataGeneral = {
          name: this.dataDetail.name,
          identityCardNumber: this.dataDetail.identityCardNumber,
          collectBussinessType: this.dataDetail.collectBussinessType,
          provinceId: this.dataDetail.provinceId,
          province: this.dataDetail.province,
          districtId: this.dataDetail.districtId,
          district: this.dataDetail.district,
          wardId: this.dataDetail.wardId,
          ward: this.dataDetail.ward,
          address: this.dataDetail.address,
          isIndustrial: this.dataDetail.isIndustrial,
          industrialArea: this.dataDetail.industrialArea,
          phoneNumber: this.dataDetail.phoneNumber,
          email: this.dataDetail.email,
          manufacturingType: this.dataDetail.manufacturingType,
          mainProduct: this.dataDetail.mainProduct,
          scaleWorker: this.dataDetail.scaleWorker,
          numberWorker: this.dataDetail.numberWorker,
          dateCollect: this.dataDetail.dateCollect,
        }
        this.collectBussinessId = this.dataDetail.id
      }
    },

    type() {
      if (this.type === 'edit') {
        this.listTabs[1].isDisabled = false
      }
      if (this.type === 'add') {
        this.listTabs[1].isDisabled = true
      }
    },
    activeTab(val) {
      if (val === null) {
        this.activeTab = 'general'
        this.listTabs[0].isRendered = true
      }
    },
  },

  async created() {
    if (this.$route.params.type) {
      this.activeTab = this.$route.params.type
      const tab = this.listTabs.find(x => x.key === this.activeTab)
      if (tab) tab.isRendered = true
    }
    if (this.activeTab === null) {
      this.activeTab = 'general'
      this.listTabs[0].isRendered = true
    }
  },
  methods: {

    isRendered(key) {
      const tab = this.listTabs.find(x => x.key === key)
      if (tab) return tab.isRendered
      return false
    },
    // thay đổi active tab
    setActive(activeTab) {
      this.activeTab = activeTab
      const tab = this.listTabs.find(x => x.key === activeTab)
      if (tab) tab.isRendered = true
    },

    accept(bvModalEvt) {
      const model = {
        ...this.dataGeneral,
        numberWorker: Number(this.dataGeneral.numberWorker),
      }
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('accept', model)
        }
      })
    },

    resetData() {
      this.activeTab = null
      this.dataGeneral = {
        name: '',
        identityCardNumber: '',
        collectBussinessType: '',
        provinceId: 0,
        province: '',
        districtId: 0,
        district: '',
        wardId: 0,
        ward: '',
        address: '',
        isIndustrial: true,
        industrialArea: '',
        phoneNumber: '',
        email: '',
        manufacturingType: '',
        mainProduct: '',
        scaleWorker: '',
        numberWorker: 0,
        dateCollect: null,
      }
    },
  },
}
</script>
<style lang="scss">
  .btn-down {
    display: grid;
    margin-bottom: 15px;
    align-items: end;
  }
</style>
