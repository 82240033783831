export default {
  // Enum loại hình của người sử dụng lao động
  collectBussinessType: [
    {
      name: 'Doanh nghiệp nhà nước',
      key: 'StateOwner',
    },
    {
      name: 'Doanh nghiệp ngoài nhà nước',
      key: 'NonStateOwner',
    },
    {
      name: 'Doanh nghiệp FDI',
      key: 'FDI',
    },
    {
      name: 'Cơ quan, đơn vị nhà nước',
      key: 'StateAgencies',
    },
    {
      name: 'Hộ kinh doanh',
      key: 'HouseHold',
    },
    {
      name: 'Cá nhân',
      key: 'Personal',
    },
  ],
  // Enum ngành sản xuất
  manufacturingType: [
    {
      name: 'Nông, lâm nghiệp và thủy sản',
      key: 'ManufacturingType1',
    },
    {
      name: 'Công nghiệp, chế biến, chế tạo',
      key: 'ManufacturingType2',
    },
    {
      name: 'SX và phân phối điện, khí đốt, hơi nước và điều hòa không khí',
      key: 'ManufacturingType3',
    },
    {
      name: 'Vận tải, kho bãi',
      key: 'ManufacturingType4',
    },
    {
      name: 'Thông tin và truyền thông',
      key: 'ManufacturingType5',
    },
    {
      name: 'Hoạt động kinh doanh bất động sản',
      key: 'ManufacturingType6',
    },
    {
      name: 'Hoạt động hành chính và dịch vụ hỗ trợ',
      key: 'ManufacturingType7',
    },
    {
      name: 'Y tế và hoạt động trợ giúp xã hội',
      key: 'ManufacturingType8',
    },
    {
      name: 'Bán buôn và bán lẻ; sửa chữa ô tô, mô tô, xe máy và xe có động cơ khác',
      key: 'ManufacturingType9',
    },
    {
      name: 'Tốt nghiệp trung học phổ thôngHoạt động làm thuê và các công việc trong hộ gia đình',
      key: 'ManufacturingType10',
    },
    {
      name: 'Khai khoáng',
      key: 'ManufacturingType11',
    },
    {
      name: 'Xây dựng',
      key: 'ManufacturingType12',
    },
    {
      name: 'Cung cấp nước, hoạt động quản lý và xử lý nước thải, rác thải',
      key: 'ManufacturingType13',
    },
    {
      name: 'Dịch vụ lưu trú và ăn uống',
      key: 'ManufacturingType14',
    },
    {
      name: 'Hoạt động tài chính, ngân hàng và bảo hiểm',
      key: 'ManufacturingType15',
    },
    {
      name: 'Hoạt động chuyên môn, khoa học và công nghệ',
      key: 'ManufacturingType16',
    },
    {
      name: 'Giáo dục và đào tạo',
      key: 'ManufacturingType17',
    },
    {
      name: 'Nghệ thuật, vui chơi và giải trí',
      key: 'ManufacturingType18',
    },
    {
      name: 'Hoạt động của ĐCS, tổ chức CT-XH, QLNN, ANQP, BĐXH bắt buộc',
      key: 'ManufacturingType19',
    },
    {
      name: 'Hoạt động, dịch vụ khác',
      key: 'ManufacturingType20',
    },
    {
      name: 'Hoạt động của các tổ chức và cơ quan quốc tế',
      key: 'ManufacturingType21',
    },
  ],
  // Enum quy mô lao động
  scaleWorker: [
    {
      name: '< 10',
      key: 'LesserThan10',
    },
    {
      name: '10 - 50',
      key: 'From10To50',
    },
    {
      name: '51 - 100',
      key: 'From51To100',
    },
    {
      name: '101 - 200',
      key: 'From101To200',
    },
    {
      name: '201 - 500',
      key: 'From201To500',
    },
    {
      name: '501 - 1.000',
      key: 'From501To1000',
    },
    {
      name: '1.001 - 3.000',
      key: 'From1001To3000',
    },
    {
      name: '3.001 - 10.000',
      key: 'From3001To10000',
    },
    {
      name: '>10.000',
      key: 'GreaterThan10000',
    },
  ],
}
