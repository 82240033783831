export default {
  LABOR_RECRUITMENT_LIST: 'CollectBussiness/collectBussinesses',
  DOWNLOAD_FILE: 'CollectBussiness/file-collect-bussiness-report',
  CREATE_EDIT_LABOR_RECRUITMENT: 'CollectBussiness/collectBussinesses',
  DETAIL_LABOR_RECRUITMENT: 'CollectBussiness/collectBussinesses/',
  DELETE_LABOR_RECRUITMENT: 'CollectBussiness/delete-collect-bussinesses',
  LIST_LABOR_RECRUITMENT_ID: 'CollectBussiness/list-collect-bussiness-jobs',
  CREATE_LABOR_RECRUITMENT_ID: 'CollectBussiness/update-collect-bussiness-jobs',

  // COMBOBOX
  COMBOBOX_CITY: 'OfficalProfile/provinces',
  COMBOBOX_DISTRICT: 'OfficalProfile/districts/',
  COMBOBOX_WARD: 'OfficalProfile/wards/',
}
