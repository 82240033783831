var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container-table",attrs:{"id":"labor-recruitment"}},[_c('button-all-header',{attrs:{"contentBtnAdd":'Thêm thông tin',"arrayExcel":[],"showBtnMultiDelete":_vm.showBtnMultiDelete,"hideExportFile":false,"hideImportFile":false,"hideDowload":false},on:{"clickDelete":_vm.deleteItems,"clickAdd":_vm.showModalCreate,"search":function($event){return _vm.search($event)}}}),_c('vue-good-table',{ref:"user-table",attrs:{"style-class":"vgt-table","columns":_vm.columns,"rows":_vm.dataList || [],"select-options":{
      enabled: true,
      vertialAlignTop: true,
      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    }},on:{"on-selected-rows-change":_vm.selectRowTable},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'dateCollect')?_c('span',[(props.row.dateCollect !== '0001-01-01T00:00:00')?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDateToDDMM")(props.row.dateCollect))+" ")]):_vm._e()]):(props.column.field === 'ActionFunction')?_c('span',[_c('span',[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Xem tài liệu'),expression:"'Xem tài liệu'",modifiers:{"hover":true,"top":true}}],staticClass:"text-body",attrs:{"icon":"EyeIcon","size":"18"},on:{"click":function($event){return _vm.showPDF(props.row.id)}}})],1),_c('span',{staticClass:"ml-2",on:{"click":function($event){return _vm.showModalEdit(props.row.id)}}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Chỉnh sửa'),expression:"'Chỉnh sửa'",modifiers:{"hover":true,"top":true}}],staticClass:"text-body",attrs:{"icon":"Edit3Icon","size":"18"}})],1),_c('span',{staticClass:"ml-2",on:{"click":function($event){return _vm.deleteItem(props.row.id)}}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Xóa'),expression:"'Xóa'",modifiers:{"hover":true,"top":true}}],staticClass:"text-body",attrs:{"icon":"Trash2Icon","size":"18"}})],1)]):_vm._e()]}}])}),_c('my-pagination',{attrs:{"totalItems":_vm.totalRecord,"currentPage":_vm.urlQuery.pageNumber},on:{"pageClick":_vm.pageChange}}),_c('confirm-modal',{attrs:{"id":_vm.confirmModalId,"content":_vm.modalContent},on:{"accept":_vm.deleteAction}}),_c('modal-create',{attrs:{"id":_vm.modalIdCreate,"title":_vm.modalType==='add' ?'Thêm thông tin' : 'Chỉnh sửa thông tin',"dataDetail":_vm.dataDetail,"type":_vm.modalType},on:{"accept":_vm.handleModal}}),_c('modal-see-pdf',{attrs:{"id":_vm.modalSeePdf,"pdfsrc":_vm.pdfSrc},on:{"downloadFile":_vm.downloadFilePdf}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }